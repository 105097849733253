import { useIntl } from 'react-intl';

import PageTransitionWrapper from 'components/animation-wrapper/page-transition-wrapper';
import TAOOHelmet from 'components/helmet/Helmet';
import useGetPointsHistory from 'hooks/points/useGetPointsHistory';

import styles from './index.module.scss';
import PointsHistoryTransactions from './all/PointsHistoryTransactions';
import PointsHistoryOnlineTransactions from './online/PointsHistoryOnlineTransactions';
import SectionNav from 'components/navigations/section-nav';

function PointsHistory() {
    const intl = useIntl();
    const { history, isLoading, fetch: refetchData } = useGetPointsHistory({ shouldFetchOnMount: true });

    return (
        <>
            <PageTransitionWrapper className={styles.container}>
                <TAOOHelmet title={intl.formatMessage({ id: 'seo.page-title.point-history' })} />

                <SectionNav
                    tabsWrapperClassName={styles.tabs}
                    tabs={[
                        {
                            id:    1,
                            label: intl.formatMessage({ id: 'points-history.nav1.title' }),
                        },
                        {
                            id:    2,
                            label: intl.formatMessage({ id: 'points-history.nav2.title' }),
                        },
                    ]}
                    tabNodes={{
                        1: (
                            <PointsHistoryTransactions 
                                isLoading={isLoading} 
                                history={history?.length && history.filter(item => item.source !== 'BUYING_ONLINE')} 
                            />
                        ),
                        2: (
                            <PointsHistoryOnlineTransactions 
                                isLoading={isLoading} 
                                history={history?.length && history.filter(item => item.source === 'BUYING_ONLINE')}
                                refetchData={refetchData} 
                            />
                        ),
                    }}
                />
            </PageTransitionWrapper>
        </>
    );
}

export default PointsHistory;
