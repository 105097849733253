import { endpoints } from 'constants/api';
import TaooServerClient from 'http/axios';

import * as types from './types';

export const setPoints =
    (points = 0) => (dispatch) => {
        dispatch({
            type:    types.SET_POINT,
            payload: points,
        });
    };

export const getPointsHistory = () => async function (dispatch) {
    dispatch({ type: types.POINTS_GET_HISTORY_REQUEST });

    try {
        const response = await TaooServerClient.get(endpoints.points.getHistory);

        if (response.status == 200) {
            dispatch(getPointsHistorySuccess(response.data));
        } else {
            dispatch(getPointsHistoryError(response));
        }
    } catch (error) {
        dispatch(getPointsHistoryError(error));
    }
};

function getPointsHistorySuccess(results) {
    return {
        type:    types.POINTS_GET_HISTORY_SUCCESS,
        history: results,
        error:   null,
    };
}

function getPointsHistoryError(error) {
    return {
        type:    types.POINTS_GET_HISTORY_ERROR,
        history: null,
        error,
    };
}
