import { generateImageUrl } from 'services/url';

export function getPointsHistoryItemRequestData(item, intl) {
    if (!('request' in item && typeof item.request === 'object' && item.request != null)) {
        return [];
    }

    const { request } = item;

    return [
        {
            title:   intl.formatMessage({ id: 'points-history.validation-info.title' }),
            isTitle: true,
        },
        {
            title:       intl.formatMessage({ id: 'points-history.validation-info.orderId' }),
            description: request.orderId,
        },
        {
            title:       intl.formatMessage({ id: 'points-history.validation-info.status' }),
            description: intl.formatMessage({ id: request.status }),
        },
        {
            title:       intl.formatMessage({ id: 'points-history.validation-info.createAt' }),
            description: request.createAt,
        },
        {
            title:       intl.formatMessage({ id: 'points-history.validation-info.message' }),
            description: request.message,
            reverseFlex: true,
        },
        {
            title:       intl.formatMessage({ id: 'points-history.validation-info.respondedAt' }),
            description: request.respondedAt,
        },
        {
            title:       intl.formatMessage({ id: 'points-history.validation-info.response' }),
            description: request.response,
            reverseFlex: true,
        },
        {
            title:       intl.formatMessage({ id: 'points-history.validation-info.points' }),
            description: `${ item.points || 0 } Pts`,
        },
        {
            title:       intl.formatMessage({ id: 'points-history.validation-info.document' }),
            description: generateImageUrl(request.document),
        },
    ];
}
