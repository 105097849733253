import moment from 'moment';
import { useIntl } from 'react-intl';

import { PointsHistoryStatus, UnknownValue } from 'constants/defaults';
import Loader from 'components/loaders/loader';
import ProfileOrderHistoryDateItem from 'pages/profile/profile-order-history/date-item';
import ProfileOrderHistoryOrderItem from 'pages/profile/profile-order-history/transactions/order-item';
import { generateImageUrl } from 'services/url';
import NoResult from 'components/no-result';
import { getPointsHistoryItemRequestData } from '../func';

// eslint-ignore-next-line
// @ts-ignore
import styles from '../index.module.scss';

interface PointsHistoryTransactionsProps {
    isLoading: boolean;
    history: any[];
}

const PointsHistoryTransactions = ({ isLoading, history }: PointsHistoryTransactionsProps) => {
    const intl = useIntl();

    return (
        <>
            {isLoading && <Loader />}

            {!isLoading && history?.length > 0 && (
                <div className={styles.historyItems}>
                    {history.map((item, idx) => {
                        const source = item.source
                            ? item.source
                                    .split('_')
                                    .join(' ')
                                    .toLowerCase()
                            : '';
                        const status = item.status ? item.status.toLowerCase() : UnknownValue;

                        const shouldShowSourceInItems = item?.companies && item.companies.length;

                        const isClaimed = item.claimedAt != null;
                        const tags =
                        source !== 'consumed' && source !== 'buying online' && status !== 'refused'
                            ? [
                                isClaimed
                                    ? {
                                        title:       intl.formatMessage({ id: 'points-history.claimedAt' }),
                                        description: moment(item.claimedAt).format('DD MMM. yyyy'),
                                    }
                                    : {
                                        title:       'En attente',
                                        description: '',
                                        type:        'danger',
                                    },
                            ]
                            : [];

                        const company = item?.companies && item.companies.length > 0 ? item.companies[0] : {};
                        const store = item.store ? item.store : null;

                        const shouldShowValidationWarning = [
                            PointsHistoryStatus.customerValidationNeeded,
                            PointsHistoryStatus.taooValidationNeeded,
                        ].includes(item.status);

                        const totalAmountSign = ['CONSUMED_IN_WHEEL', 'CONSUMED_TAOO', 'CONSUMED'].includes(item.source) ? '-' : '+';

                        // const shouldAddTotalAmountSign = !['CUSTOMER_VALIDATION_NEEDED', 'TAOO_VALIDATION_NEEDED', 'REFUSED', 'PENDING'].includes(item.status);

                        return (
                            <div className={styles.historyItem} key={item.id || idx}>
                                <ProfileOrderHistoryDateItem date={item.createdAt || UnknownValue} />
                                <ProfileOrderHistoryOrderItem
                                    defaultActiveStatus={shouldShowValidationWarning}
                                    data={{
                                        image: company?.logo ? generateImageUrl(company?.logo) : null,
                                        title: company?.name ?? intl.formatMessage({ id: item.source }),

                                        // hideTotalAmountSign: !shouldAddTotalAmountSign,
                                        totalAmount:            `${ totalAmountSign }${ item.points }`,
                                        totalAmountCurrency:    ' Pts',
                                        totalAmountColor:       ['CUSTOMER_VALIDATION_NEEDED', 'TAOO_VALIDATION_NEEDED', 'REFUSED', 'PENDING'].includes(item.status) ? '#f0c514' : null,
                                        totalAmountLineThrough: ['REFUSED'].includes(item.status),
                                        tags,
                                        items:                  [
                                            item?.card
                                                ? {
                                                    title:       intl.formatMessage({ id: 'point-history.card' }),
                                                    description: item.card,
                                                }
                                                : null,
                                            {
                                                title:       intl.formatMessage({ id: 'point-history.status' }),
                                                description: status,
                                            },
                                            shouldShowSourceInItems
                                                ? {
                                                    title:       intl.formatMessage({ id: 'point-history.source' }),
                                                    description: source,
                                                }
                                                : null,
                                            store
                                                ? {
                                                    title:       intl.formatMessage({ id: 'point-history.store' }),
                                                    description: store,
                                                }
                                                : null,
                                        ].filter(Boolean),
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>
            )}

            {!isLoading && (!history || !history?.length) && <NoResult customStyle={{ marginTop: '1rem' }} message="Aucune transaction" />}
        </>
    );
};

export default PointsHistoryTransactions;
