import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getPointsHistory } from 'store/points/action';

export default function useGetPointsHistory({ shouldFetchOnMount = true }) {
    const dispatch = useDispatch();
    const { history, historyIsLoading: isLoading, historyError: error } = useSelector((state) => state.points);

    useEffect(() => {
        if (shouldFetchOnMount && !isLoading) {
            dispatch(getPointsHistory());
        }
    }, []);

    const fetch = () => dispatch(getPointsHistory());

    return {
        history,
        isLoading,
        error,
        fetch,
    };
}
