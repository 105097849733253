import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';

import { spring } from 'constants/index';
import useUpdateEffect from 'hooks/update-effect/useUpdateEffect';
import { neitherNullNorUndefined } from 'services/validation';

import styles from './index.module.scss';

const NavigationTabs = [
    {
        id:    1,
        label: 'example1',
    },
    {
        id:    2,
        label: 'example2',
    },
];

const NavigationTabNodes = {
    1: <h1>example1 here</h1>,
    2: <h1>example2 here</h1>,
};

function SectionNav({
    tabs = NavigationTabs,
    tabNodes = NavigationTabNodes,

    // for manual change use these props
    manualNavigationChanger = false,
    manualNavigation = false,

    disablePointerClickIndicator = false,

    nodesWrapperClassName,
    tabsWrapperClassName,
}) {
    const [selectedTab, setSelectedTab] = useState(tabs[0]);

    useUpdateEffect(() => {
        if (manualNavigation) {
            selectedNext();
        }
    }, [manualNavigationChanger]);

    const selectedNext = () => {
        setSelectedTab((prev) => {
            const selectedIndex =
                tabs
                        .map((tab, idx) => {
                            if (tab.id === prev.id) {
                                return idx;
                            }
                        })
                        .filter(neitherNullNorUndefined)?.[0] || 0;
            const nextIndex = selectedIndex + 1 < tabs.length ? selectedIndex + 1 : 0;

            return tabs[nextIndex];
        });
    };

    return (
        <div className="flex-grow-1 d-flex flex-column">
            <nav className={`${ styles.navigation } ${ tabsWrapperClassName }`}>
                <ul>
                    {tabs.map((item) => (
                        <li
                            key={item.id}
                            className={`${ item.id === selectedTab.id ? styles.selected : '' } ${
                                disablePointerClickIndicator ? styles.normalPointer : ''
                            }`}
                            onClick={() => !manualNavigation && setSelectedTab(item)}
                            style={{ width: `calc(100%/${tabs.length})` }}
                        >
                            {item?.label || ''}
                            {item.id === selectedTab.id ? (
                                <motion.div transition={spring} className={styles.underline} layoutId="underline" />
                            ) : null}
                        </li>
                    ))}
                </ul>
            </nav>
            <div className={nodesWrapperClassName}>
                <AnimatePresence exitBeforeEnter>
                    <motion.div
                        key={selectedTab.id}
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                    >
                        {tabNodes[selectedTab.id]}
                    </motion.div>
                </AnimatePresence>
            </div>
        </div>
    );
}

export default SectionNav;
